<template>
  <section>
    <EnterEmailRewards v-if="enterEmail" v-model:showModal="enterEmail" />
    <PageMetadata />
    <flash-alert class="my-2" v-if="!embed" v-model:show="flashAlert.show" :level="flashAlert.level">
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <template v-if="isLoggedIn">
      <div class="row" v-if="pageDetails.image != '' && pageDetails.image != null && pageDetails.image_above_h1 == 1">
        <div class="col-md-12"><img :src="`${imagePath}` + `${pageDetails.image}`" :alt="`${pageDetails.page_h1}`" class="w-100" /></div>
      </div>
      <h1 class="header text-center pt-80" :class="pageDetails.h1_classes">{{ pageDetails.page_h1 }}</h1>
      <div class="row" v-if="pageDetails.image != '' && pageDetails.image != null && pageDetails.image_above_h1 == 0">
        <div class="col-md-12"><img :src="`${imagePath}` + `${pageDetails.image}`" :alt="`${pageDetails.page_h1}`" class="w-100" /></div>
      </div>
      <component :is="'style'" v-if="pageDetails.additional_style != null && pageDetails.additional_style != ''">
        {{ pageDetails.additional_style }}
      </component>
      <div id="page_content" class="mt-10 content-wrapper" v-html="pageDetails.description" @click="handleClick"></div>
      <div id="page_content" class="mt-10 content-wrapper" v-html="pageDetails.description_below_dynamic"></div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-md-12 mt-5">
          <p class="regular-p">Kindly <a href="/login">log-in</a> to see the available products for your branch. If you are currently not a Mayesh Wholesale customer, complete the <a href="/register">registration form </a>to our list of specials.</p>
        </div>
      </div>
    </template>
  </section>
</template>
<script>
import axios from "axios";
import { misc, loyalty } from "@/mixins";
import { mapGetters } from "vuex";
import { FlashAlert, EnterEmailRewards, PageMetadata } from "@/components";
export default {
  name: "PetalsRewards",
  mixins: [misc, loyalty],
  components: { FlashAlert, EnterEmailRewards, PageMetadata },
  data() {
    return {
      pageDetails: {},
      slug: 'petals-rewards',
      enterEmail: false,
      inputFields: [
        {
          'name': 'email',
          'type': 'email',
          'value': ''
        },
      ],
      emailAddress: '',
      htmlContent: "<div class='w-100 p-3 text-center'>Please confirm the email address for Petals Rewards notifications.</div>",
    };
  },
  computed: {
    ...mapGetters(["canEnrollLoyalty", "canChangeLoyaltyStatus", "loggedinUserDetails"]),
  },
  mounted() {
    localStorage.setItem("currentPage", this.slug);
    this.inputFields[0].value = this.loggedinUserDetails.email;
    this.emailAddress = this.loggedinUserDetails.email;
    this.fetchDetails();
    this.fetchMetaDetails();
  },
  methods: {
    handleClick(e) {
      if (e.target.classList.contains('sign-up-btn')) {
        e.preventDefault()
        if (this.canEnrollLoyalty && this.canChangeLoyaltyStatus) {
          this.enterEmail = true;
        }
      }
    },
    fetchDetails() {
      axios
        .post(
          `${process.env.VUE_APP_API_BASE}auth/getPageContent`,
          {
            slug: this.slug,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.pageDetails = response.data.content;

          if (
            "hubspot_portal_id" in this.pageDetails &&
            "hubspot_form_id" in this.pageDetails &&
            this.pageDetails.hubspot_portal_id &&
            this.pageDetails.hubspot_form_id
          ) {
            const script = document.createElement("script");
            script.src = "//js.hsforms.net/forms/v2.js";
            document.body.appendChild(script);
            script.addEventListener("load", () => {
              if (window.hbspt) {
                window.hbspt.forms.create({
                  portalId: this.pageDetails.hubspot_portal_id,
                  formId: this.pageDetails.hubspot_form_id,
                  target: "#hubspot-form",
                });
              }
            });
          }
          if ("jot_form_id" in this.pageDetails && this.pageDetails.jot_form_id) {
            let scriptJotForm = document.createElement("script");
            scriptJotForm.src = "//js.jotform.com/JotFormAnywhere.js";
            document.body.appendChild(scriptJotForm);
            scriptJotForm.addEventListener("load", () => {
              window.JotformAnywhere.insertForm({
                formID: this.pageDetails.jot_form_id,
                formEmbedUrl: "https://form.jotform.com/jsform/" + this.pageDetails.jot_form_id,
                insertTo: "#jot-form",
                putEditButton: false,
                putDeleteButton: false
              });
            });
          }
          if (this.canEnrollLoyalty && this.canChangeLoyaltyStatus) {
              setTimeout(() => {
                let elements = document.querySelectorAll('.sign-me-up');
                if (elements.length > 0) {
                  elements.forEach((element) => {
                    element.classList.remove('d-none');
                  })
                }
              }, 1000);
          }

          setTimeout(() => {
            var anchor = document.getElementById('how-to-redeem');
            anchor.addEventListener("click", () => {
              console.log('IN ANCHOR')
            });
          }, 1000);
          this.scrollToElement();
        });
    },
    scrollToElement() {
      if (this.$route.hash) {
        setTimeout(() => {
          const elem = document.querySelector(this.$route.hash);
          if (elem) {
            document.getElementById(elem.id).scrollIntoView({
              block: "start",
              behavior: "smooth",
              inline: "start",
            });
          }
        }, 50);
      }
    },
  },
};
</script>
<style>
#page_content {
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;
  margin-bottom: 30px;
  color: #231f20;
}

.mt-10 {
  margin-top: 10px;
}

.scrollElement {
  scroll-margin-top: 80px;
}
@media screen and (max-width: 480px) {
  .content-wrapper {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>
